// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.mjs";
import * as Belt_List from "bs-platform/lib/es6/belt_List.mjs";
import * as Caml_option from "bs-platform/lib/es6/caml_option.mjs";

function resolve(prim) {
  return Promise.resolve(prim);
}

function map(prom, f) {
  return prom.then(function (x) {
              return Promise.resolve(Curry._1(f, x));
            });
}

function tap(prom, f) {
  return map(prom, (function (x) {
                Curry._1(f, x);
                return x;
              }));
}

function catchMap(prom, f) {
  return prom.catch(function (err) {
              return Promise.resolve(Curry._1(f, err));
            });
}

function sleep(ms) {
  return new Promise((function (resolve, param) {
                setTimeout((function (param) {
                        return resolve(undefined);
                      }), ms);
                
              }));
}

function ignore(param) {
  
}

function tapIgnore(prom, f) {
  tap(prom, f);
  
}

function doIf(cond, prom) {
  if (cond) {
    return Curry._1(prom, undefined);
  } else {
    return Promise.resolve(undefined);
  }
}

function doOption(opt, prom) {
  if (opt !== undefined) {
    return Curry._1(prom, Caml_option.valFromOption(opt));
  } else {
    return Promise.resolve(undefined);
  }
}

function allSequentiallyList(inputProms) {
  var loop = function (proms, results) {
    if (!proms) {
      return Promise.resolve(results);
    }
    var rest = proms.tl;
    return Curry._1(proms.hd, undefined).then(function (result) {
                return loop(rest, {
                            hd: result,
                            tl: results
                          });
              });
  };
  return loop(inputProms, /* [] */0);
}

function allSequentially(arr) {
  return map(allSequentiallyList(Belt_List.fromArray(arr)), Belt_List.toArray);
}

export {
  resolve ,
  map ,
  tap ,
  catchMap ,
  sleep ,
  ignore ,
  tapIgnore ,
  doIf ,
  doOption ,
  allSequentiallyList ,
  allSequentially ,
  
}
/* No side effect */
