// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_json from "bs-platform/lib/es6/js_json.mjs";
import * as Pluralize from "pluralize";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.mjs";
import * as JsonUtils$DraftbitBuilder from "./utilities/JsonUtils.bs.js";

function t_encode(v) {
  if (typeof v === "string") {
    if (v === "List") {
      return ["List"];
    } else {
      return ["New"];
    }
  } else {
    return [
            "Edit",
            Decco.stringToJson(v.VAL)
          ];
  }
}

function t_decode(v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a polyvariant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a polyvariant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected polyvariant, found empty array", v);
  }
  var tagged = jsonArr$1.map(Js_json.classify);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "Edit" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          }
          var v0 = Decco.stringFromJson(Belt_Array.getExn(jsonArr$1, 1));
          if (v0.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      NAME: "Edit",
                      VAL: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "List" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: "List"
                  };
          }
      case "New" :
          if (tagged.length !== 1) {
            return Decco.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: /* Ok */0,
                    _0: "New"
                  };
          }
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid polyvariant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

function render(lne, noun) {
  if (typeof lne === "string") {
    if (lne === "List") {
      return Pluralize.plural(noun);
    } else {
      return "New " + Pluralize.singular(noun);
    }
  } else {
    return "Edit " + Pluralize.singular(noun);
  }
}

function toQueryParam(lne) {
  return JsonUtils$DraftbitBuilder.toQueryParam(t_encode(lne));
}

export {
  t_encode ,
  t_decode ,
  render ,
  toQueryParam ,
  
}
/* pluralize Not a pure module */
